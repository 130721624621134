<template>
  <div class="facebook">
    <div>
      <iframe
        :src="
          `https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F${$route.params.id}%2F&tabs=timeline&height=600&width=340&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=145602495517964&background=transparent`
        "
        height="600"
        width="340"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialFacebookPage'
}
</script>

<style lang="scss" scoped>
iframe {
  @apply border-0;
}

.facebook {
  @apply flex justify-center items-center w-full h-screen;
  background: #ebedf0;

  > div {
    @apply mx-auto;
  }
}
</style>
